import React from "react"
import styled from "styled-components"
import { standardWrapper } from "../../styles/helpers"
import TempMap from "./TempMap"

const LotPickerStyled = styled.div`
  .wrapper {
    ${standardWrapper};
  }

  .mapWrap {
    width: 100%;
  }
`

const LotPicker = () => {
  return (
    <LotPickerStyled>
      <div className="wrapper">
        <div className="mapWrap">
          <iframe
            width="100%"
            height="900"
            // src="https://mapsplus.com/showcase/real-estate/coopers-crossing-full"
            src="/lot-map.html"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </LotPickerStyled>
  )
}

export default LotPicker
