import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Intro from "../components/FindYourLot/Intro"
import LotPicker from "../components/FindYourLot/LotPicker"

const FindYourLot = props => {
  const { seoInfo, topQuote } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Intro data={topQuote} />
      <LotPicker />
    </Layout>
  )
}

export const findYourLotQuery = graphql`
  query findYourLotPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }
  }
`

export default FindYourLot
