import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const MapWrap = styled.div`
  width: 100%;
  height: 100%;
`

const getData = graphql`
  {
    map: file(relativePath: { eq: "temp=pick-your-lots.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const TempMap = () => {
  const data = useStaticQuery(getData)
  const imageData = data.map.childImageSharp.fluid
  return (
    <MapWrap>
      <Img
        fluid={imageData}
        alt="Coopers Crossing Category - Pick Your Lot Map"
      />
    </MapWrap>
  )
}

export default TempMap
